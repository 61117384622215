import { render, staticRenderFns } from "./BasicExpansionPanels.vue?vue&type=template&id=668093cf&scoped=true"
import script from "./BasicExpansionPanels.vue?vue&type=script&lang=js"
export * from "./BasicExpansionPanels.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "668093cf",
  null
  
)

export default component.exports