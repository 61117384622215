import { render, staticRenderFns } from "./SectionName.vue?vue&type=template&id=2643a2c8&scoped=true"
import script from "./SectionName.vue?vue&type=script&lang=js"
export * from "./SectionName.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2643a2c8",
  null
  
)

export default component.exports